html, #root, #reactRoot {
    height: 100vh;
    margin: 0;
    padding: 0;
}
body {
    height: 100vh;
    margin: 0;
    padding: 0;
    
    
    font-family: 'Roboto';
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#reactRoot > div {
    position: absolute;
    top: 58px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: auto;
    display:flex;
}

h1, h2, h3, h4 {
    font-family: 'Roboto';
}
h4 {
    margin: 0px;
}
pre {
    font-family: 'Roboto';
}

/*https://stackoverflow.com/questions/64196389/dark-dropdown-items-in-react-bootstrap*/
.nav-item .dropdown-menu {
    background: #000000;
}

.nav-item .dropdown-item {
    color: #ffffff;
}

    .nav-item .dropdown-item:hover {
        background: #f0ad4e;
    }