
.editForm {
	overflow: auto;
	display: flex;
	flex-direction: column;
	width: 100%;
}
	.editForm > div {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.editForm .card {
		margin: 10px;
	}
	
