.shopApp {
	display: flex;
	flex: 1;
	flex-direction: row;
}
.finalizeOrder {
	flex-direction: column;
}

	.finalizeOrder .itemsContainter {
		
		min-height: 65px;
		overflow-y: scroll;
		
	}
		.finalizeOrder .itemsContainter.resize {
			resize: vertical;
			min-height: 300px;
			overflow-y: scroll;
			border: solid 1px rgba(0, 0, 0, 0.15);
		}

.shopApp pre {
	border: solid 1px rgba(0, 0, 0, 0.15);
	padding: 5px;
	max-width: 300px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

	.shopApp > .menu {
		flex: 1;
		overflow: auto;
	}

	.shopApp > .cart {
		flex: 0 0 30%;
		overflow: auto;
		background-color: white;
		margin-left: 20px;
		border: solid 1px lightGrey;
		padding: 15px;
	}
	.shopApp .productTable td {
		vertical-align: middle;
	}
	.shopApp .productTable td {
		vertical-align: middle;
		cursor: pointer;
	}
		.shopApp .productTable td.description {
			
		}
		.shopApp .productTable td.price {
			text-align: right;
			width: 35px;
		}
		.shopApp .productTable td.actions {
			text-align: right;
			width: 25px;
		}



@media only screen and (max-width: 800px) {
	.placeOrder {
		flex-direction: column-reverse;
		overflow: scroll;
	}
		.placeOrder > .cart {
			margin-left: 0px;
			overflow: unset;
		}

			.placeOrder > .cart.empty {
				display: none;
			}

		.placeOrder > .menu {
			flex: 1;
			overflow: unset;
		}
}