
.homePage {
	overflow: auto;
	display: flex;
	flex-direction: column;
	width: 100%;
}

	.homePage .header {
		flex: 0 0 100px;
	}

	.homePage .body {
		flex: 1;
		display: flex;
		overflow: auto;
	}
